import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-12.png'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/MistakesToAvoidAsADigipayAgent`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '4 Mistakes To Avoid As A Digipay Agent',
  },
  {
    property: 'og:description',
    content: 'Iwasan mong gawin ang mga ito, ka-Digipay.',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="4 Mistakes To Avoid As A Digipay Agent" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          4 MISTAKES TO AVOID AS A DIGIPAY AGENT
        </Heading>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            “DI KO SURE, MARE”
          </Heading>
          <Text>
            Kapag may potential suki na nagtanong sa’yo, “Pwede bang magbayad
            diyan ng PAG-IBIG at Cashalo?” Iwasan ang hindi siguradong sagot. Sa
            kahit anong negosyo, alam mo dapat ang iyong binebenta. ‘Di mo naman
            kailangang i-memorize ang lahat ng billers dahil wala namang quiz!
            Just open the Digipay App, click Bills Payment at i-type ang biller
            sa search bar. Napakadali lang i-navigate ng app, ‘di ba?
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            “TAGAL KO NAMANG YUMAMAN”
          </Heading>
          <Text>
            2 months ka pa lang as Digipay Agent pero gusto mo na agad ng
            sandamakmak na kita? Chill ka lang, ka-Digipay. Walang ‘instant
            yaman’ sa negosyo dahil lahat ‘yan ay pinaghihirapan. Bakit hindi mo
            ipagkalat sa buong barangay na pwede na sila magbayad ng bills, top
            up ng load, GCash o PayMaya, Smart Padala sa’yo? Pwede ka ring
            mag-refer ng kaibigan na gustong maging Digipay Agent para may 100
            credits ka. Sa Digipay, basta may tiyaga...may EXTRA INCOME!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            “BADTRIP AKO, SARADO ANG TINDAHAN”
          </Heading>
          <Text>
            Hala, baka maubusan ka ng suki niyan, ka-Digipay! Iwasan mo ang bad
            vibes sa negosyo para hindi rin mabadtrip ang mga suki mo. That’s a
            no, no. Sayang naman ang kita kung papairalin lang ang mood swing,
            ‘di ba? Always smile para positive energy din ang darating sa iyong
            tindahan.
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            “KATAMAD BASAHIN ‘YUNG AGENT MANUAL”
          </Heading>
          <Text>
            And lastly, para ito sa mga bagong Digipay Agent. Gawin mong
            bestfriend ang iyong Agent Manual. Ginawa ito upang bigyan ka ng
            basic knowledge tungkol sa Digipay app. Ang lahat ng transactions ay
            mangyayari lamang sa app or via Web kaya dapat familiar ka sa icons
            at nilalaman nito. Happy learning, happy earning!
          </Text>

          <Text>
            Guilty ka ba as a Digipay Agent? Tandaan, laging iwasan ang mga ito
            upang maging maayos ang iyong pagnenegosyo. Hanggang sa muli,
            ka-Digipay!
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
